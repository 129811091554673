// export const URL ='https://madyapi.akosmdtech.com';
export const URL ='https://frontapi.mchemist.com'; // live
//  "homepage": "https://frontmady.akosmdtech.com/", (package.js)
export const BASE_URL ='https://frontapi.mchemist.com/uploads'; //live
export const CLIENT_ID = 'b150e0b836bf6a04e1546671690f2899'; // live
export const SECRET_KEY = '605bcc5691ae77a84e635ffa63903587d9583fd1188f6e803f1bec3a1c664d4c';// live

//export const URL ='https://frontapi.mchemist.in'; // staging
//export const BASE_URL ='https://frontapi.mchemist.in/uploads'; //staging
//export const CLIENT_ID = '02b3ccfffb3e1d31ca6fee0404329968'; // staging
//export const SECRET_KEY = '0b1add6f365f3eba18cdbf7aa386a599fd7add3e8495528d42b2a15aa6e69200';// staging

